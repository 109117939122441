import React from 'react';
import {Box, Grid, Button} from '@material-ui/core';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import {MetaTags} from 'react-meta-tags';
import axios from 'axios';
import Cookies from 'js-cookie';
import LinearProgress from '@mui/material/LinearProgress';
///import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import {API_URL} from '../utils/EnvVars';
import Header from "./HeaderMy";
import '../styles/bizofficer-a.css';
import 'font-awesome/css/font-awesome.min.css';

	var vertical='top',horizontal='center';

  export default function Execute() {
	const userObj = useSelector(state => state.userDetails);	
	const isloggedin = Cookies.get('isacademyloggedin');
	const [severityAlert, setSeverityAlert] = React.useState("error");
	const [showAlert, setShowAlert] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");
	const [showProgress, setShowProgress] = React.useState(false);

	const [organisationId, setOrganisationId] = React.useState(0);
	const [organisationList, setOrganisationList] = React.useState([]);
	const [checkedDashboard, setCheckedDashboard] = React.useState(false);
	const [checkedSmartLearning, setCheckedSmartLearning] = React.useState(false);
	const [checkedSelfAssessor, setCheckedSelfAssessor] = React.useState(false);
	const [checkedTopicTest, setCheckedTopicTest] = React.useState(false);
	const [checkedMockTest, setCheckedMockTest] = React.useState(false);
	const [checkedClassTest, setCheckedClassTest] = React.useState(false);
	const [checkedReport, setCheckedReport] = React.useState(false);

	const handleChangeDashboard = (event) => {
	  setCheckedDashboard(event.target.checked);
	};
	const handleChangeSmartLearning = (event) => {
		setCheckedSmartLearning(event.target.checked);
	};
	const handleChangeSelfAssessor = (event) => {
		setCheckedSelfAssessor(event.target.checked);
	};
	const handleChangeTopicTest = (event) => {
		setCheckedTopicTest(event.target.checked);
	};
	const handleChangeMockTest = (event) => {
		setCheckedMockTest(event.target.checked);
	};
	const handleChangeClassTest = (event) => {
		setCheckedClassTest(event.target.checked);
	};
	const handleChangeReport = (event) => {
		setCheckedReport(event.target.checked);
	};

	function hideAlert(){
		setShowAlert(false);
	}
	
	// check if logged in
	React.useEffect(() => {
		if(!isloggedin){
			window.location.href='login';
		}
	}, [isloggedin]);


	React.useEffect(() => {		
		if(userObj.emailId && userObj.token){
			getApiOrganisationList(userObj.emailId,userObj.token);
		}
	}, [userObj]);

	async function getApiOrganisationList(emailId,token){
      let axiosHeader = {
        headers: {
          'Content-Type': 'multipart/mixed',
          'token': token,
          'loginEmailId': emailId,
        }
      }; 
	  axios.get(API_URL+'/organisationlist',axiosHeader)
			   .then(response => {
			 	///console.log("Just Checking >> "+response.data); 
				 setShowProgress(false); 
				 setOrganisationList(response.data);				 				
	  })
	}

	React.useEffect(() => {		
		setShowProgress(true);
		if(userObj.emailId && userObj.token && organisationId>0){
			getApiOrgNavMenu(userObj.emailId,userObj.token,organisationId);
		}
	}, [organisationId]);

	async function getApiOrgNavMenu(emailId,token,organisationId){
      let axiosHeader = {
        headers: {
          'Content-Type': 'multipart/mixed',
          'token': token,
          'loginEmailId': emailId,
        }
      }; 
	  axios.get(API_URL+'/navigationoption?organisationId='+organisationId,axiosHeader)
			   .then(response => {
			 	///console.log("Just Checking >> "+response.data);  
				setCheckedDashboard(response.data.dashboard);
				setCheckedSmartLearning(response.data.smartLearning);
				setCheckedSelfAssessor(response.data.selfTest);
				setCheckedTopicTest(response.data.topicTest);
				setCheckedMockTest(response.data.mockTest);
				setCheckedClassTest(response.data.classTest);
				setCheckedReport(response.data.report);
				setShowProgress(false);
	   })
	}

	function saveData(){
	
			if(organisationId>0 && (checkedDashboard===true || checkedSmartLearning===true || checkedSelfAssessor===true || checkedTopicTest===true || checkedMockTest===true || checkedClassTest===true || checkedReport===true) ){ 
				let axiosHeader = {
					headers: {
						'Content-Type': 'multipart/mixed',
						'token': userObj.token,
						'loginEmailId': userObj.emailId,
					}
				}; 
				const data = new FormData();
				data.append('organisationId', organisationId);

				if(checkedDashboard===true){
					data.append('dashboard', checkedDashboard);
				}else{
					data.append('dashboard', false);
				}

				if(checkedSmartLearning===true){
					data.append('smartLearning', checkedSmartLearning);
				}else{
					data.append('smartLearning', false);
				}
				
				if(checkedSelfAssessor===true){
					data.append('selfTest', checkedSelfAssessor);
				}else{
					data.append('selfTest', false);
				}

				if(checkedTopicTest===true){
					data.append('topicTest', checkedTopicTest);
				}else{
					data.append('topicTest', false);
				}

				if(checkedMockTest===true){
					data.append('mockTest', checkedMockTest);
				}else{
					data.append('mockTest', false);
				}

				if(checkedClassTest===true){
					data.append('classTest', checkedClassTest);
				}else{
					data.append('classTest', false);
				}

				if(checkedReport===true){
					data.append('report', checkedReport);
				}else{
					data.append('report', false);
				}
				 
				axios.post(API_URL+'/navigationoptionsave', data, axiosHeader)
				.then((response) => {
					console.log("RESPONSE RECEIVED: ", response.data.responseTxt);
					if(response.data.responseTxt==="OK"){
						setOrganisationId(0);
						setSeverityAlert("success");
						setShowAlert(true);
						setAlertMessage("Navigation option has been saved.");
					  	window.location.href.reload();	
						return false;
					}else{
						setSeverityAlert("error");
						setShowAlert(true);
						setAlertMessage("It could not be saved.");			
						return false;
					}
				})
				.catch((error) => {
					console.log("AXIOS ERROR: ", error);
				})	
			}		
	}
		
	return (
		<div> 
				<MetaTags>
            		<title>Navigation Option</title>
          		</MetaTags>
				
				<Header />
				{(document.readyState!=="complete")?<LinearProgress />:null}

				<Box>	

					

					<Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={3} xs={12}>
								</Grid>
								<Grid item md={6} xs={12}>
								<h3 className="heading" >Navigation Option</h3>
								
						<table style={{backgroundColor:'#000000',width:'100%' }} cellSpacing="1" cellPadding="3" >
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td style={{textAlign:'center'}} colSpan="2" >
                                <Select 
                                style={{width:'100%'}}
                                labelId="select-class-label"
                                id="select-class"
                                value={organisationId}									
                                onChange={event => setOrganisationId(event.target.value)}
                                variant="standard"
                              >
                                  <MenuItem value="0">Select Organisation</MenuItem>
                                  {(organisationList!=null && organisationList.length>0)?
                                    organisationList.map((myRow,key)=>(
                                      <MenuItem key={'mtk'+key} value={myRow.organisationId} >{myRow.organisationName}</MenuItem> 
                                    )):null}
                              </Select>
                            </td>                            
                        </tr>
						{organisationId>0?
							<tr style={{backgroundColor:'#FFFFFF'}} >
								<td colSpan={2} >
								{showProgress?<><LinearProgress />Wait...</>:null}	
								<FormGroup>
									<FormControlLabel control={<Checkbox checked={checkedDashboard} onChange={handleChangeDashboard} />} label="Dashboard" />
									<FormControlLabel control={<Checkbox checked={checkedSmartLearning} onChange={handleChangeSmartLearning} />} label="Smart Learning" />
									<FormControlLabel control={<Checkbox checked={checkedSelfAssessor} onChange={handleChangeSelfAssessor} />} label="Self Assessor" />
									<FormControlLabel control={<Checkbox checked={checkedTopicTest} onChange={handleChangeTopicTest} />} label="Topic Test" />
									<FormControlLabel control={<Checkbox checked={checkedMockTest} onChange={handleChangeMockTest} />} label="Mock Test" />
									<FormControlLabel control={<Checkbox checked={checkedClassTest} onChange={handleChangeClassTest} />} label="Class Test" />
									<FormControlLabel control={<Checkbox checked={checkedReport} onChange={handleChangeReport} />} label="Report & Analytics" />  
								</FormGroup>
								</td>                            
							</tr>
                        :null}                        
                       </table>

					    {organisationId>0?	
							<Box mt={2}>
							<Button onClick={() => {saveData()}} style={{marginRight:12}} size="small" variant="contained" style={{backgroundColor:'#008000',color:'#FFF',marginRight:10}} >Save</Button>
							</Box>
						:null}

								</Grid>
								<Grid item md={3} xs={12}>
								</Grid>
							</Grid>
					</Box>

					

				</Box>


				<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity={severityAlert} sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
				</Stack>

		</div>	
	);
  }