import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Grid, Box, Button} from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from "@material-ui/core/styles";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import {API_URL} from '../utils/EnvVars';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  paper: {
    fontSize: '12px !important',    
  }
});

var vertical='top',horizontal='center';

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CustomizedDialogs = React.forwardRef((props, ref) => {  
  const classes = useStyles();
  const userObj = useSelector(state => state.userDetails);
  const [open, setOpen] = React.useState(false); 
  const [severityAlert, setSeverityAlert] = React.useState("error");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const [className, setClassName] = React.useState("noselect");
  const [subject, setSubject] = React.useState("noselect");
  

  var myCurrentDate=new Date();
	var myPastFromDate=new Date(myCurrentDate);
  myPastFromDate.setDate(myPastFromDate.getDate() + 365);

  React.useImperativeHandle(ref, () => ({
    handleClickOpen(){
      setOpen(true);
    }
  }));
  
  const handleClose = () => {
    setOpen(false);
  };

  function hideAlert(){
		setShowAlert(false);
	}

  // function handleClassName(clsName){    
  //   if(clsName && props.classes.length>0){
  //     for(let i=0; i<props.classes.length; i++){
  //       if(props.classes[i].className===clsName){
  //         setOrganisationId(props.classes[i].organisationId);
  //         setClassName(props.classes[i].className);
  //       }
  //     }
  //   }
  // }

  React.useEffect(() => {	
    if(open && props.teacherObj!=null && props.teacherObj.autoId>0){	
      setClassName(props.teacherObj.className);
      setSubject(props.teacherObj.subject);

    }else{
      setClassName("noselect");
      setSubject("noselect");
    
    }
	}, [open,props.teacherObj]);

	function saveData(){
    if(className==="noselect"){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Select batch.");			
      return false;
    }    
    if(subject==="noselect"){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Select subject.");			
      return false;
    }    

		if(className!=="noselect" && subject!=="noselect"){ 
			let axiosHeader = {
				headers: {
					'Content-Type': 'multipart/mixed',
          'token': userObj.token,
          'emailId': userObj.emailId,
				}
			}; 
			const data = new FormData();
      
      if(props.teacherObj && props.teacherObj.autoId>0){
        data.append('id', props.teacherObj.autoId);
      }
      data.append('className', className);
      data.append('subject', subject);

      let apiURL='/add-subjectassociation';
      let myMessage="Subject association has been created.";
      if(props.teacherObj && props.teacherObj.autoId>0){
          apiURL='/edit-subjectassociation';
          myMessage="Subject association has been updated.";
      }
             
			axios.post(API_URL+apiURL, data, axiosHeader)
			.then((response) => {
				//console.log("RESPONSE RECEIVED: ", response.data.responseTxt);
				if(response.data.responseTxt==="success"){
          setSeverityAlert("success");
          setOpen(false);
					setShowAlert(true);
					setAlertMessage(myMessage);
          window.location.reload();	
					return false;
        }else if(response.data.responseTxt==="AlreadyExistsError"){
            setSeverityAlert("error");
            setOpen(true);
            setShowAlert(true);
            setAlertMessage("This subject association was created earlier. Kindly make another association");
            return false;  			

        }else if(response.data.responseTxt==="unauthorized"){
            Cookies.remove('isacademyloggedin');
            window.location.href='login';
 
				}else{
          setSeverityAlert("error");
					setShowAlert(true);
					setAlertMessage("This subject association could not be saved.");			
					return false;
				}
			})
			.catch((error) => {
				console.log("ERROR: ", error);
			})	
		}		
	}
  
  return (
    <>
      <Dialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{marginBottom:'-30px'}} onClose={handleClose}></DialogTitle>
          <DialogContent >
            <Grid item   md={12} xs={12} className="test-instructions" >
                  <div className="btmbdrgry"><h3>{(props.teacherObj && props.teacherObj.autoId>0)?<>Edit</>:<>New</>} Subject Association</h3></div>
                  <p>
                      <table style={{backgroundColor:'#000000',width:'100%' }} cellSpacing="1" cellPadding="3" >
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td colSpan="2" >
                              <Select 
                                style={{width:'100%'}}
                                classes={{ paper: classes.paper }}
                                labelId="select-class-label"
                                id="select-class"
                                value={className}									
                                onChange={event => setClassName(event.target.value)}
                                variant="standard"
                              >
                                  <MenuItem value="noselect">Select Batch</MenuItem>
                                  {props.classes?props.classes.map((myClass,mykey)=>(
                                    <MenuItem key={'tchr'+mykey} value={myClass.className}>{myClass.className}</MenuItem>
                                  )):null}
                              </Select>
                            </td>                            
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td colSpan="2" >
                              <Select 
                                style={{width:'100%'}}
                                classes={{ paper: classes.paper }}
                                labelId="select-class-label"
                                id="select-class"
                                value={subject}									
                                onChange={event => setSubject(event.target.value)}
                                variant="standard"
                              >
                                  <MenuItem value="noselect">Select Subject</MenuItem>
                                  {props.subjects?props.subjects.map((myRow,mykey)=>(
                                    <MenuItem key={'tchr'+mykey} value={myRow.subject}>{myRow.subject}</MenuItem>
                                  )):null}
                              </Select>
                            </td>                            
                        </tr>

                      </table>


                      <Box mt={2}>
                          <Button onClick={() => {handleClose()}} style={{marginRight:12}} size="small" variant="outlined" >Cancel</Button>
					    	          <Button onClick={() => {saveData()}} size="small" variant="contained" style={{backgroundColor:'#008000',color:'#FFF',marginRight:10}} >Save</Button>
								      </Box>

                  </p>
            </Grid>
          </DialogContent>
      </Dialog>
      
        <Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity={severityAlert} sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
				</Stack>

    </>
  );

});

export default CustomizedDialogs;