import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {Box, Grid, Button} from '@material-ui/core';
import {TextField, FormControl, Stack, Snackbar, Alert, LinearProgress, Radio, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {MetaTags} from 'react-meta-tags';
import axios from 'axios';
import Cookies from 'js-cookie';
import Tooltip from '@mui/material/Tooltip';
import DialogStudentAssoSave from '../components/DialogStudentAssoSave';
import { useSelector } from 'react-redux';
import {API_URL} from '../utils/EnvVars';
import Header from "./HeaderMy";
///import moment from 'moment';
import '../styles/bizofficer-a.css';
import 'font-awesome/css/font-awesome.min.css';

  
  var vertical='top',horizontal='center';
  
  export default function Execute() {
	const userObj = useSelector(state => state.userDetails);	
	const isloggedin = Cookies.get('isacademyloggedin');
	const childRef = React.useRef();
	const childRefNewSave = React.useRef();
	const [deleteDialogShow, setDeleteDialogShow] = React.useState(false);
	const [filter, setFilter] = React.useState(false);
	const [keyword, setKeyword] = React.useState("");
	const [studentObj, setStudentObj] = React.useState({});
	const [rows, setRows] = React.useState([]);
	const [selectedRow, setSelectedRow] = React.useState(0);
	const [classes, setClasses] = React.useState([]);
	const [students, setStudents] = React.useState([]);
	const [subjects, setSubjects] = React.useState([]);

	const [open, setOpen] = React.useState(false); 
	const [severityAlert, setSeverityAlert] = React.useState("error");
	const [showAlert, setShowAlert] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");
  

	var myCurrentDate=new Date();
	var myPastFromDate=new Date(myCurrentDate);
    myPastFromDate.setDate(myPastFromDate.getDate() - 366);

	function hideAlert(){
		setShowAlert(false);
	}

	const columns = [
		{
			field: "Select",
			headerName: "",
			width: 90,
			sortable: false,
				renderCell: (cellValues) => {
				return (
				  <Radio 
					checked={cellValues.id === selectedRow}
					value={cellValues.id}
					name="radio-buttons"
					inputProps={{ 'aria-label': {cellValues} }}
				  />
				 )
			  }
		  },
		{ field: 'id', headerName: 'Sr. No.', width: 40 },
		{ field: 'studentName', headerName: 'Student Name', flex: 1, renderCell: (params) =>  (
			<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
		   ), },
		{ field: 'className', headerName: 'Batch', flex: 1, renderCell: (params) =>  (
			<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
		   ), },
		{ field: 'subject', headerName: 'Subject', flex: 1, renderCell: (params) =>  (
			<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
		   ), }
	  ];
	

	// check if logged in
	React.useEffect(() => {
		if(!isloggedin){
			window.location.href='login';
		}
	}, [isloggedin]);

	React.useEffect(() => {		
		if(userObj.emailId && userObj.token){
			getApiData(userObj.emailId,userObj.token,keyword);
		}
	}, [userObj,filter]);

	async function getApiData(emailId,token,keyword){
			axios.get(API_URL+'/schoolstudentassociation?emailId='+emailId+'&token='+token+"&keyword="+keyword)
			   .then(response => {
			 	///console.log("Just Checking >> "+response.data);  
				 if(response.data.responseTxt==="success"){
				 	setRows(response.data.teacherAssociations);	
					setClasses(response.data.classes);		
					setStudents(response.data.students);
					setSubjects(response.data.subjects);
				 }	 				
			})
	}

	function deleteTeacherAssociation(){
		if(studentObj!=null && userObj.emailId && userObj.token){
			///deleteTeacherAssociationApiData(userObj.emailId,userObj.token,teacherObj.autoId);
			let axiosHeader = {
					headers: {
					'Content-Type': 'multipart/mixed',
			  		'token': userObj.token,
			  		'loginEmailId': userObj.emailId,
				}
			}; 

		  const data = new FormData();
		  data.append('studentAssociationId', studentObj.autoId);
				 
		  axios.post(API_URL+'/deleteschoolstudentassociation', data, axiosHeader)
				.then((response) => {
					console.log("RESPONSE RECEIVED: ", response.data.responseTxt);
				if(response.data.responseTxt==="success"){
					setSeverityAlert("success");
					setOpen(false);
					setShowAlert(true);
					setAlertMessage("Student association has been deleted.");
			  		window.location.reload();	
					return false;
				}else{
			  		setSeverityAlert("error");
					setShowAlert(true);
					setAlertMessage("This student association could not be saved.");			
					return false;
				}
			})
				.catch((error) => {
					console.log("Delete teacher association AXIOS ERROR: ", error);
				})	
		}		
	
		
	}

	function saveTeacher(opt){
		if(opt===1){
			setStudentObj(null);
		}	
		childRefNewSave.current.handleClickOpen();
	}

	function selectRow(obj){
		if(obj && rows[obj.id-1]){			
			setSelectedRow(rows[obj.id-1].id);
			setStudentObj(rows[obj.id-1]);
			///childRef.current.handleClickOpen();
		}
	}

	function search(){
		setFilter(!filter);
	}

	function onEnter(event){
		if(event.key === 'Enter'){
			search();
		}
	}
	
	function deleteDialogHide(){
		setDeleteDialogShow(false);
	}

	

	return (
		<div> 
				<MetaTags>
            		<title>Students Association</title>
          		</MetaTags>
				
				<Header />
				{(document.readyState!=="complete")?<LinearProgress />:null}

				<Box className='mainBox'>	

					<Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>
									<h3 className="heading" >Students Association</h3>
								</Grid>
							</Grid>
					</Box>

					<Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>

									<FormControl style={{float:'left',width:150,marginRight:60,marginTop:8}}>
										<TextField
										id="keywordtxt"
										label="Search"
										defaultValue={keyword}
										sx={{ width: 200 }}
										InputLabelProps={{
										shrink: true,
										}}
										onChange={event => setKeyword(event.target.value)}
										onKeyPress={(event)=>{onEnter(event)}}
										/>
									</FormControl>

									<Button onClick={() => {search()}} style={{marginTop:8}} size="small" variant="contained" color="secondary" >Search</Button>

									<span style={{marginTop:30}}>	
										<i onClick={()=>{saveTeacher(1)}} title="Add New" className="fa fa-plus-circle" style={{fontSize:40, color:'#0158dc',marginLeft:15, cursor:'pointer'}} />
										{selectedRow>0?<>
											<i onClick={()=>{saveTeacher(2)}} title="Edit" className="fa fa-pencil-square-o" style={{fontSize:40, color:'#0158dc',marginLeft:15, cursor:'pointer'}} />
											<i onClick={()=>{setDeleteDialogShow(true)}} title="Delete" className="fa fa-trash" style={{fontSize:40, color:'#0158dc',marginLeft:15, cursor:'pointer'}} />
										</>:null}
									</span>	
								</Grid>
							</Grid>
					</Box>

					<div className="tblReport" style={{height: '62vh', width: '100%' }}>
						<DataGrid
							rows={rows}
							columns={columns}
							pageSize={50}
							rowHeight={40}
							rowsPerPageOptions={[50]}
							ActionsComponent={()=>{alert('Hello')}}		
							style={{fontSize:12}}
							components={{
								NoRowsOverlay: () => (
								  <Stack height="100%" alignItems="center" justifyContent="center">
									<span style={{color:'#FF0000',fontWeight:'bold'}}>No student found!</span>
								  </Stack>
								)}}	
							onRowClick={itm => selectRow(itm)}	
						/>
					</div>

				</Box>

			<DialogStudentAssoSave studentObj={studentObj} subjects={subjects} students={students} classes={classes} ref={childRefNewSave} />						
			
			<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity={severityAlert} sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
			</Stack>

				<Dialog
					open={deleteDialogShow}
					onClose={deleteDialogHide}
					aria-labelledby="alert-dialog-section-title"
					aria-describedby="alert-dialog-section-description"
				>
					<DialogTitle id="alert-dialog-section-title">
						Alert
					</DialogTitle>
					<DialogContent>
					<DialogContentText id="alert-dialog-section-description">
						Are you sure, You want to delete student association.
					</DialogContentText>
					</DialogContent>
					<DialogActions>
					<Button variant="outlined" size="small" onClick={()=>{deleteDialogHide()}}>Don't Delete</Button>
					<Button variant="contained" size="small" color="secondary" onClick={()=>{deleteTeacherAssociation()}} autoFocus>Yes, Delete</Button>
					</DialogActions>
				</Dialog>


		</div>	
	);
  }