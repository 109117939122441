export const API_URL=getAPIURL();

function getAPIURL(){
    let url;
    switch(window.location.host) {
      case 'academy.easetolearn.com':
        url = 'https://org1.easetolearn.com';
        break;
      case 'academyuat.easetolearn.com':
        url = 'https://org.easetolearn.com';
        break;
      default:
        url = 'http://localhost:8097';
    }
    return url;
}
