import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Profile from "./pages/Profile";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Login from "./pages/Login";

import StudentsList from "./pages/StudentsList";
import NavigationOptions from "./pages/NavigationOptions";
import TeachersList from "./pages/TeachersList";
import ClassesList from "./pages/ClassesList";
import TeachersAssociation from "./pages/TeachersAssociation";
import StudentsAssociation from "./pages/StudentsAssociation";
import SubjectsAssociation from "./pages/SubjectsAssociation";

// const getBasename = () => {
//   ///return '/'+process.env.PUBLIC_URL.split('/').pop();
//   ///return "/reacteasetolearn";
//   return "";
// };

function App() {

  return (
    <Router basename={'/academy'} >
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch> 
          <Route exact path="/navigationoptions" component={NavigationOptions} />
          <Route exact path="/studentsassociation" component={StudentsAssociation} />
          <Route exact path="/students" component={StudentsList} />
          <Route exact path="/teachers" component={TeachersList} />
          <Route exact path="/classes" component={ClassesList} />
          <Route exact path="/teachersassociation" component={TeachersAssociation} />
          <Route exact path="/subjectsassociation" component={SubjectsAssociation} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword" component={ResetPassword} />  
          <Route exact path="/profile" component={Profile} />  
          <Route exact path="/login" component={Login} />  
          <Route exact path="/" component={Login} />  
          
          
        </Switch>
      </div>
    </Router>
  );
}

export default App;
