import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Grid, Box, Button} from '@material-ui/core';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from "@material-ui/core/styles";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {API_URL} from '../utils/EnvVars';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  paper: {
    fontSize: '12px !important',    
  }
});

var vertical='top',horizontal='center';

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CustomizedDialogs = React.forwardRef((props, ref) => {  
  const classes = useStyles();
  const userObj = useSelector(state => state.userDetails);
  const [open, setOpen] = React.useState(false); 
  const [severityAlert, setSeverityAlert] = React.useState("error");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  

  const [sid, setSid] = React.useState(0);
  const [autoId, setAutoId] = React.useState(0);
  const [studentName, setStudentName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [duplicate, setDuplicate] = React.useState(false);
  
  const [className, setClassName] = React.useState("noselect");
  const [courseId, setCourseId] = React.useState(0);
  const [courseTitle, setCourseTitle] = React.useState("");
  const [organisationId, setOrganisationId] = React.useState(0);
  const [admissionNumber, setAdmissionNumber] = React.useState("");
  
  React.useImperativeHandle(ref, () => ({
    handleClickOpen(){
      setOpen(true);
    }
  }));
  
  const handleClose = () => {
    setOpen(false);
  };

  function hideAlert(){
		setShowAlert(false);
	}

  function handleClassName(clsName){    
    if(clsName && props.classes.length>0){
      for(let i=0; i<props.classes.length; i++){
        if(props.classes[i].className===clsName){
          setCourseId(props.classes[i].courseId);
          setCourseTitle(props.classes[i].courseTitle);
          setOrganisationId(props.classes[i].organisationId);
          setClassName(props.classes[i].className);
        }
      }
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

	function saveData(){
    if(className==='noselect'){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Select batch.");	
      return false;
    }    
    if(!studentName || (studentName && studentName.length<1)){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Student Name can not be empty.");	
      return false;
    }
    if(!phone){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Mobile can not be empty.");	
      return false;
    }
    if(email && email.length>0 && !validateEmail(email) ){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Email is not valid.");			
      return false;
    }
    if((!newPassword || (newPassword && newPassword.length<1)) && !props.studentObj){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Password can not be empty.");			
      return false;
    }

		if(studentName && phone){ 
			let axiosHeader = {
				headers: {
					'Content-Type': 'multipart/mixed',
          'token': userObj.token,
          'emailId': userObj.emailId,
				}
			}; 

			const data = new FormData(); 

      let apiURL='/newschoolstudent';
      if(duplicate===true && props.studentObj && autoId && autoId>0){
        data.append('duplicate', 1);
        data.append('autoId', autoId);    
        data.append('sid', sid);    
        apiURL='/newschoolstudent';
      }else if(props.studentObj && autoId && autoId>0){
        data.append('autoId', autoId);
        data.append('sid', sid);
        apiURL='/editschoolstudent';
      }else{
        data.append('duplicate', 0);
      }

      data.append('studentName', studentName);
      if(phone){
        data.append('phone', phone);
      }
      if(email){
        data.append('emailId', email);
      }
      if(newPassword){
        data.append('newPassword', newPassword);
      }
      if(className){
        data.append('className', className);
      }
      if(courseId){
        data.append('courseId', courseId);
      }
      if(courseTitle){
        data.append('courseTitle', courseTitle);
      }
      if(organisationId){
        data.append('organisationId', organisationId);
      }
      if(admissionNumber){
        data.append('admissionNumber', admissionNumber);
      }
      
			axios.post(API_URL+apiURL, data, axiosHeader)
			.then((response) => {
				//console.log("RESPONSE RECEIVED: ", response.data.responseTxt);
				if(response.data.responseTxt==="success"){
          setSeverityAlert("success");
          setOpen(false);
					setShowAlert(true);
					setAlertMessage("Student details has been saved.");
          window.location.reload();	
					return false;
        }else if(response.data.responseTxt==="AlreadyExistsMobileError"){
            setSeverityAlert("error");
            setOpen(true);
            setShowAlert(true);
            setAlertMessage("Mobile is already associated with another student.");
            return false;  		
            
        }else if(response.data.responseTxt==="AlreadyExistsEmailError"){
            setSeverityAlert("error");
            setOpen(true);
            setShowAlert(true);
            setAlertMessage("Email id is already associated with another student.");
            return false;  		

        }else if(response.data.responseTxt==="AlreadyExistsSubjectsAndBatch"){
            setSeverityAlert("error");
            setOpen(true);
            setShowAlert(true);
            setAlertMessage("This batch & subject are already associated with this student.");
            return false;  		

        }else if(response.data.responseTxt==="unauthorized"){
            Cookies.remove('isacademyloggedin');
            window.location.href='login';
  
				}else{
          setSeverityAlert("error");
					setShowAlert(true);
					setAlertMessage("Student details could not be saved.");			
					return false;
				}
			})
			.catch((error) => {
				console.log("AXIOS ERROR: ", error);
			})	
		}		
	}

  React.useEffect(() => {
    if(props.studentObj && open){	
      setAutoId(props.studentObj.autoId);
      setSid(props.studentObj.sid);
      setStudentName(props.studentObj.studentName);
      setPhone(props.studentObj.phoneNo);
      setEmail(props.studentObj.emailId);
      setNewPassword("");
      handleClassName(props.studentObj.className);
      setAdmissionNumber(props.studentObj.admissionNumber);

    }else{
      setAutoId(0);	
      setStudentName("");
      setPhone("");
      setEmail("");
      setNewPassword("");
      setClassName("noselect");
      setAdmissionNumber("");
    }
    setDuplicate(false);
	}, [props.studentObj,open]);
  
  function numberOnly(event){
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    } 
  }


  return (
    <>
      <Dialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{marginBottom:'-30px'}} onClose={handleClose}></DialogTitle>
          <DialogContent >
            <Grid item   md={12} xs={12} className="test-instructions" >
                  <div className="btmbdrgry"><h3>{duplicate?<>Create duplicate student with new subject</>:(props.studentObj!==null)?<>Edit Student</>:<>New Student</>}</h3></div>
                  <p>
                      <table style={{backgroundColor:'#000000',width:'100%' }} cellSpacing="1" cellPadding="3" >
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td>Batch</td>
                            <td>
                              <Select 
                                style={{width:'100%'}}
                                classes={{ paper: classes.paper }}
                                labelId="select-class-label"
                                id="select-class"
                                value={className}									
                                onChange={event => handleClassName(event.target.value)}
                                variant="standard"
                              >
                                  <MenuItem value="noselect">Select Batch</MenuItem>
                                  {props.classes?props.classes.map((myClass,mykey)=>(
                                    <MenuItem key={'tchr'+mykey} value={myClass.className}>{myClass.className}</MenuItem>
                                  )):null}
                              </Select>
                            </td>                            
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td>Student Name</td>
                            <td style={{textAlign:'center'}} >
                            <TextField onChange={event => setStudentName(event.target.value)} value={studentName} sx={{width:'99%',fontSize: 10}} variant="standard" />
                            </td>                            
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td>Mobile</td>
                            <td style={{textAlign:'center'}} >
                            <TextField onChange={event => setPhone(event.target.value)} value={phone} onKeyPress={(event)=>{numberOnly(event)}} style={{width:'99%'}} inputProps={{ maxLength: 10 }} variant="standard" />
                            </td>
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                          <td>Email</td>
                          <td style={{textAlign:'center'}} >
                          <TextField onChange={event => setEmail(event.target.value)} value={email} style={{width:'99%'}} variant="standard" />
                          </td>                            
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                          <td>Password</td>
                          <td style={{textAlign:'center'}} >
                          <TextField type='password' onChange={event => setNewPassword(event.target.value)} value={newPassword} style={{width:'99%'}} variant="standard" />
                          </td>
                        </tr>
                        {(props.studentObj && props.studentObj.autoId>0)?
                          <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td colSpan={2} >
                              <FormControlLabel
                                label="Create duplicate"
                                control={
                                  <Checkbox
                                    checked={duplicate}
                                    onChange={event => setDuplicate(event.target.checked)}
                                  />
                                }
                              />
                            </td>
                          </tr>
                        :null}
                        {/* <tr style={{backgroundColor:'#FFFFFF'}} >
                          <td>Admission Number</td>
                          <td style={{textAlign:'center'}} >
                          <TextField onChange={event => setAdmissionNumber(event.target.value)} value={admissionNumber} style={{width:'99%'}} variant="standard" />
                          </td>                            
                        </tr>                         */}
                                              
                      </table>

                      <Box mt={2}>
                          <Button onClick={() => {handleClose()}} style={{marginRight:12}} size="small" variant="outlined" >Cancel</Button>
					    	          <Button onClick={() => {saveData()}} size="small" variant="contained" style={{backgroundColor:'#008000',color:'#FFF',marginRight:10}} >Save</Button>
								      </Box>

                  </p>
            </Grid>
          </DialogContent>
      </Dialog>
      
        <Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity={severityAlert} sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
				</Stack>

    </>
  );

});

export default CustomizedDialogs;