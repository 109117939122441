import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {Box, Grid, Button} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import {MetaTags} from 'react-meta-tags';
import axios from 'axios';
import Cookies from 'js-cookie';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import DialogClassUpdate from '../components/DialogClassUpdate';
import DialogClassSave from '../components/DialogClassSave';
import { useSelector } from 'react-redux';
import {API_URL} from '../utils/EnvVars';
import Header from "./HeaderMy";
///import moment from 'moment';
import '../styles/bizofficer-a.css';
import 'font-awesome/css/font-awesome.min.css';

  
  
const columns = [
	{ field: 'id', headerName: '#', width: 40 },
	{ field: 'className', headerName: 'Batch', flex: 1, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), },
	{ field: 'courseTitle', headerName: 'Course Title', flex: 1, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), }   
	/*
	{
	  field: 'age',
	  headerName: 'Age',
	  type: 'number',
	  width: 130,
	},
	{
	  field: 'fullName',
	  headerName: 'Full name',
	  description: 'This column has a value getter and is not sortable.',
	  sortable: false,
	  width: 160,
	  valueGetter: (params) =>
		`${params.getValue(params.id, 'firstName') || ''} ${
		  params.getValue(params.id, 'lastName') || ''
		}`,
	},
	*/
  ];
  
  export default function Execute() {
	const userObj = useSelector(state => state.userDetails);	
	const isloggedin = Cookies.get('isacademyloggedin');
	const childRef = React.useRef();
	const childRefNewSave = React.useRef();
	const [filter, setFilter] = React.useState(false);
	const [keyword, setKeyword] = React.useState("");
	const [dataObj, setDataObj] = React.useState({});
	const [rows, setRows] = React.useState([]);
	const [courses, setCourses] = React.useState([]);

	// check if logged in
	React.useEffect(() => {
		if(!isloggedin){
			window.location.href='login';
		}
	}, [isloggedin]);

	React.useEffect(() => {		
		if(userObj.emailId && userObj.token){
			getApiData(userObj.emailId,userObj.token,keyword);
		}
	}, [userObj,filter]);

	async function getApiData(emailId,token,keyword){
			let axiosHeader = {
				headers:{
					'Content-Type': 'multipart/mixed',
					'emailId': emailId,
					'token': token,
				}
			};
			axios.get(API_URL+'/classes?keyword='+keyword, axiosHeader)
			   .then(response => {
			 	///console.log("Just Checking >> "+response.data);  
			 	if(response.data.responseTxt==="success"){ 
					setRows(response.data.list);
				}else if(response.data.responseTxt==="unauthorized"){
					Cookies.remove('isacademyloggedin');
					window.location.href='login';
				} 
				 				 				
			})
	}

	React.useEffect(() => {		
		if(userObj.emailId && userObj.token && userObj.websiteId){
			getApiCourses(userObj.emailId,userObj.token,userObj.websiteId);
		}    
	}, [userObj]);

  async function getApiCourses(emailId,token,websiteId){
		axios.get(API_URL+'/website-courses?loginEmailId='+emailId+'&token='+token+'&websiteId='+websiteId)
		   .then(response => {
			 if(response && response.data){					 
				 setCourses(response.data);
			 }	 				
		})
	}

	function newData(){
		childRefNewSave.current.handleClickOpen();
	}

	function goToDetail(obj){
		if(obj && rows[obj.id-1]){
			setDataObj(rows[obj.id-1]);
			childRef.current.handleClickOpen();
		}
	}

	function search(){
		setFilter(!filter);
	}

	function onEnter(event){
		if(event.key === 'Enter'){
			search();
		}
	}
	
	return (
		<div> 
				<MetaTags>
            		<title>Batches</title>
          		</MetaTags>
				
				<Header title="Batches" />
				{(document.readyState!=="complete")?<LinearProgress />:null}

				<Box className='mainBox'>

					<Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>

									<FormControl style={{float:'left',width:150,marginRight:60,marginTop:8}}>
										<TextField
										id="keywordtxt"
										label="Search"
										defaultValue={keyword}
										sx={{ width: 200 }}
										InputLabelProps={{
										shrink: true,
										}}
										onChange={event => setKeyword(event.target.value)}
										onKeyPress={(event)=>{onEnter(event)}}
										size="small"
										/>
									</FormControl>

									<Button onClick={() => {search()}} style={{marginTop:-7}} size="small" variant="contained" color="secondary" >Search</Button>

									<span style={{marginTop:30}}>	
										<i onClick={()=>{newData()}} title="Add New Batch" className="fa fa-plus-circle" style={{fontSize:40, color:'#0158dc',marginLeft:15, cursor:'pointer'}} />
										{/* <i title="Download" className="fa fa-download" style={{fontSize:40, color:'#333',marginLeft:15, cursor:'pointer'}} />		 */}
									</span>	
								</Grid>
							</Grid>
					</Box>

					<div className="tblReport" style={{height: '100vh', width: '100%' }}>
						<DataGrid
							rows={rows}
							columns={columns}
							pageSize={50}
							rowHeight={40}
							rowsPerPageOptions={[50]}
							ActionsComponent={()=>{alert('Hello')}}		
							style={{fontSize:12}}	
							onRowClick={itm => goToDetail(itm)}			
							components={{
								NoRowsOverlay: () => (
								  <Stack height="100%" alignItems="center" justifyContent="center">
									<span style={{color:'#FF0000',fontWeight:'bold'}}>No batches found!</span>
								  </Stack>
								)}}	
						/>
					</div>

				</Box>

			<DialogClassUpdate dataObj={dataObj} courses={courses} ref={childRef} />								
			<DialogClassSave courses={courses} ref={childRefNewSave} />						
			
		</div>	
	);
  }