import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'; // FOR CALLING API ASYNCHRONOUSLY 
import rootCombineReducers from './store/root.combine.reducers.js';
import {persistStore, persistReducer} from 'redux-persist'; // FOR SAVING DATA IN LOCAL STORAGE IN LOCAL COMPUTER
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key: 'easetolean',
	storage,	
};

const persistedReducer = persistReducer(persistConfig, rootCombineReducers);

const store = createStore(persistedReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);

export default store;