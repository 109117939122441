import React from 'react';
import {Box, Grid, Button} from '@material-ui/core';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import {MetaTags} from 'react-meta-tags';
import {API_URL} from '../utils/EnvVars';
import Cookies from 'js-cookie';
import '../styles/bizofficer-a.css';
import 'font-awesome/css/font-awesome.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { addUser } from '../store/user.actions';


  export default function Execute() {
	const userObj = useSelector(state => state.userDetails);
	const isloggedin = Cookies.get('isacademyloggedin');
	const dispatch = useDispatch();
	const [userId, setUserId] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [showAlert, setShowAlert] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");

	var vertical='top',horizontal='center';

	if(isloggedin){
		window.location.href='students';
	}

	function hideAlert(){
		setShowAlert(false);
	}

	function login(){
		///alert('Login Now');
		if(!userId){
			setShowAlert(true);
			setAlertMessage("Enter your user-id.");			
			return false;
		}else if(!password){
			setShowAlert(true);
			setAlertMessage("Enter your password.");			
			return false;	
		}else{
			document.getElementById('waitloginbtnid').style.display='';
			document.getElementById('loginbtnid').style.display='none';
			let axiosHeader = {
				headers: {
					'Content-Type': 'multipart/mixed',
				}
			};		  
			const data = new FormData();
			data.append('userId', userId);
			data.append('password', password);
			axios.post(API_URL+'/schooluserlogin', data, axiosHeader)
			.then((response) => {
				console.log("RESPONSE RECEIVED: ", response);
				if(response && response.data && response.data.responseTxt==="OK"){					
					dispatch(addUser(response.data));
					Cookies.set('isacademyloggedin', 1, { expires: new Date(new Date().getTime() + 3600000*24*400) });
					document.getElementById('waitloginbtnid').style.display='none';
					document.getElementById('loginbtnid').style.display='';
					window.location.href="students";
				}else{
					document.getElementById('waitloginbtnid').style.display='none';
					document.getElementById('loginbtnid').style.display='';
					setShowAlert(true);
					setAlertMessage("Your user-id or password is invalid.");			
					return false;
				}
			})
			.catch((error) => {
				console.log("AXIOS ERROR: ", error);
			})
		}  
	}
	
	function onEnter(event){
		if(event.key === 'Enter'){
			login();
		}
	}

		return (
			<div> 
				<MetaTags>
            		<title>Academy Login - Smart E-Learning Platform </title>
            		<meta name="description" content="Academy Login" />
          		</MetaTags>
				
				<Box className="loginBGImg" style={{backgroundImage:"url('"+process.env.PUBLIC_URL+"/student_login_bg.jpg')", backgroundPosition: 'center', height: '100vh', overflow: 'hidden' }}>					
				<span id="organisationIdSpanId" hidden>{userObj.organisationId}</span>
				<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity="error" sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
				</Stack>

				<Box>
						<Grid container style={{justifyContent: 'center'}} spacing={2}>
							<Grid item className="dhslogin" md={6} xs={12}>

							
								<Grid className="flex-container loginbox">								
									<Grid md={12} xs={12}>
										<img alt="logo" src={process.env.PUBLIC_URL+"/ease_to_learn_logo.png"} style={{height:80,marginTop:20,marginBottom:20}} />
									</Grid>
									<Grid md={12} xs={12}>
										<TextField 
											onKeyPress={(event)=>{onEnter(event)}}
											onChange={event => setUserId(event.target.value)}
											color="warning"
											id="outlined-userid-input"
											label="User Id"
											fullWidth	
											variant="filled"
											sx={{backgroundColor:'#FFF',borderTopLeftRadius: 10,borderTopRightRadius: 10}}
										/>
									</Grid>
									<Grid md={12} xs={12}>
										<TextField
											onKeyPress={(event)=>{onEnter(event)}}
											onChange={event => setPassword(event.target.value)}
											color="warning"
											id="outlined-password-input"
											label="Password"
											type="password"											
											fullWidth	
											variant="filled"
											sx={{backgroundColor:'#FFF',borderTopLeftRadius: 10,borderTopRightRadius: 10}}							
										/>
									</Grid>
									{/* <Grid md={12} xs={12}>
										<Link href="forgotpassword" className="loginfrgot" >Forgot Password?</Link>
									</Grid> */}
									<Grid md={12} xs={12}>
										<Button id="loginbtnid" onClick={()=>{login()}} className="loginbtn" variant="contained">LOGIN</Button>
										<LoadingButton 
											className="loginbtn"
											loading
											loadingPosition="start"											
											variant="outlined"
											color="secondary"
											id="waitloginbtnid" 
											style={{textTransform: 'none',display:'none'}}
										>
											Wait...
										</LoadingButton>
									</Grid>

								
								</Grid>
							

							</Grid>
											

						</Grid>
				</Box>




				</Box>
				
			</div>
		);
 
}