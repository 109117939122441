import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SwipeableTemporaryDrawer() {
  const userObj = useSelector(state => state.userDetails);
  const [openSubList, setOpenSubList] = React.useState(true);

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  function handleClickSubList(){    
    setOpenSubList(!openSubList);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function goToLocation(url){
    window.location=url;
  }
  function logout(){
    Cookies.remove('isacademyloggedin');
    window.location.href='login';
  }


  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"      
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack direction="row" spacing={2} style={{margin: 20}} >
        <Avatar onClick={()=>{goToLocation('profile')}} style={{cursor:'pointer'}} alt={userObj.fullName} src="/static/images/avatar/1.jpg" sx={{ width: 29, height: 29, marginRight:1 }} />
        <span onClick={()=>{goToLocation('profile')}} style={{cursor:'pointer', marginTop: 1, fontSize: 14}} >{userObj.fullName}</span>
      </Stack>
      <Divider />

      {/* <ListItem button onClick={()=>{goToLocation('studentsassociation')}} >
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Students Association" />
      </ListItem> */}

      <ListItem button onClick={()=>{goToLocation('students')}} >
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Students" />
      </ListItem>

      <ListItem button onClick={()=>{goToLocation('teachersassociation')}} >
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Teachers Association" />
      </ListItem>
      
      <ListItem button onClick={()=>{goToLocation('teachers')}} >
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Teachers" />
      </ListItem>

      <ListItem button onClick={()=>{goToLocation('subjectsassociation')}} >
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Subjects Association" />
      </ListItem>

      <ListItem button onClick={()=>{goToLocation('classes')}} >
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Batches" />
      </ListItem>

      <ListItem button onClick={()=>{logout()}} >
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
      </ListItem>
      
    </div>
  );

  return (
    <div>
          <Button style={{color:'#FFFFFF'}} onClick={toggleDrawer('left', true)}><MenuIcon /></Button>
          <SwipeableDrawer
            anchor='left'
            open={state['left']}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            {list('left')}
          </SwipeableDrawer>
    </div>
  );
}
