import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {MetaTags} from 'react-meta-tags';
///import axios from 'axios';
import { useSelector } from 'react-redux';
///import {API_URL} from '../utils/EnvVars';
import Header from "./HeaderMy";
import '../styles/bizofficer-a.css';
  
  export default function Execute() {
	const userObj = useSelector(state => state.userDetails);

	///const [profile, setProfile] = React.useState({});
	// React.useEffect(() => {
	// 	///alert('ss');
	// 	getApiData();	
	// }, []); 

	// async function getApiData(){
	// 		axios.get(API_URL+'/studentdetail?organisationId=71&studentId=iit_demot')
	// 		   .then(response => {
	// 			///console.log("Just Checking >> "+response.data);  
	// 			setProfile(response.data[0]);				 				
	// 		})
	// }

	return (
		<div> 
				<MetaTags>
            		<title>Profile</title>
          		</MetaTags>
				
				<Header />
				<Box className='mainBox'>	

					<Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>
									<h3 className="heading" >Profile</h3>
								</Grid>
							</Grid>
					</Box>

					<Box mt={1}>
						<Grid className="divBorderBox" container spacing={2}>						
								
								<Grid item md={6} xs={6}>Student Name</Grid>
								<Grid item md={6} xs={6}>{userObj.studentName}</Grid>
								<Grid item md={6} xs={6}>Email</Grid>
								<Grid item md={6} xs={6}>{userObj.emailId}</Grid>
								
								<Grid item md={6} xs={6}>Phone</Grid>
								<Grid item md={6} xs={6}>{userObj.phoneNo}</Grid>

							{(userObj.schools!=null && userObj.schools[0]!=null && userObj.schools[0].schoolName!=null)?
								<>
								<Grid item md={6} xs={6}>School</Grid>
								<Grid item md={6} xs={6}>{userObj.schools[0].schoolName}</Grid>
								</>
							:null}	

							{(userObj.schools!=null && userObj.schools[0]!=null && userObj.schools[0].className!=null)?
								<>
								<Grid item md={6} xs={6}>Class</Grid>
								<Grid item md={6} xs={6}>{userObj.schools[0].className}</Grid>
								</>
							:null}

								<Grid item md={6} xs={6}>Account Status</Grid>
								<Grid item md={6} xs={6}>{userObj.status}</Grid>								
						</Grid>
					</Box>

				</Box>

		</div>	
	);
  }