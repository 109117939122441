import React from 'react';
import {Box, Grid, Button, Link} from '@material-ui/core';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import {MetaTags} from 'react-meta-tags';
import {API_URL} from '../utils/EnvVars';
import Cookies from 'js-cookie';
import '../styles/bizofficer-a.css';
import 'font-awesome/css/font-awesome.min.css';

  export default function Execute() {
	const isloggedin = Cookies.get('isloggedin');
	const [emailId, setEmailId] = React.useState("");
	const [showAlert, setShowAlert] = React.useState(false);
	const [submitResponse, setSubmitResponse] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");
	
	var vertical='top',horizontal='center';

	if(isloggedin){
		window.location.href='dashboard';
	}
	function hideAlert(){
		setShowAlert(false);
	}

	function submit(){
		
		if(!emailId){
			setShowAlert(true);
			setAlertMessage("Enter your email id.");
			document.getElementById("passwordinputid").focus();			
			return false;
		}else{
			document.getElementById('waitloginbtnid').style.display='';
			document.getElementById('loginbtnid').style.display='none';
			let axiosHeader = {
				headers: {
					'Content-Type': 'multipart/mixed',
				}
			};		  
			const data = new FormData();
			data.append('emailId', emailId);
			axios.post(API_URL+'/sendresetpasswordkey', data, axiosHeader)
			.then((response) => {
				///console.log("RESPONSE RECEIVED: ", response);
				if(response && response.data && response.data.responseTxt=="OK"){					
					document.getElementById('waitloginbtnid').style.display='none';
					document.getElementById('loginbtnid').style.display='';
					setSubmitResponse(true);
				}else if(response.data.responseTxt=="InvalidEmailId"){
					document.getElementById('waitloginbtnid').style.display='none';
					document.getElementById('loginbtnid').style.display='';
					setShowAlert(true);
					setAlertMessage("Sorry, We could not find your email id in our record. Enter correct email id.");			
					return false;	
				}else{
					document.getElementById('waitloginbtnid').style.display='none';
					document.getElementById('loginbtnid').style.display='';
					setShowAlert(true);
					setAlertMessage("Error somewhere, Try after sometime.");			
					return false;
				}
			})
			.catch((error) => {
				console.log("AXIOS ERROR: ", error);
			})
		}  
	}
	
	function onEnter(event){
		if(event.key === 'Enter'){
			submit();
		}
	}

	  
		return (
			<div> 
				<MetaTags>
            		<title>Forgot Password</title>
            		<meta name="description" content="Forgot Password" />
          		</MetaTags>
				
				<Box className="loginBGImg" style={{backgroundImage:"url('"+process.env.PUBLIC_URL+"/student_login_bg.jpg')"}}>					
				
				<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity="error" sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
				</Stack>

				<Box>
						<Grid container style={{justifyContent: 'center'}} spacing={2}>
							<Grid item className="dhslogin animate-zoom" md={6} xs={12}>

								<Grid className="flex-container loginbox">								
									<Grid md={12} xs={12}>
										<img className="loginLogo" src={process.env.PUBLIC_URL+"/ease_to_learn_logo.png"} />
									</Grid>
									<Grid md={12} xs={12}>
										{submitResponse?<span style={{color:'#dea338',fontSize:16,fontWeight:'bold',lineHeight:2}}>We have sent you reset password url. Kindly check your email: {emailId}</span>:
										<TextField
											onKeyPress={(event)=>{onEnter(event)}}
											onChange={event => setEmailId(event.target.value)}
											color="warning"
											id="passwordinputid"
											label="Enter your email"
											autoComplete="current-password"
											fullWidth	
											variant="filled"
											sx={{backgroundColor:'#FFF',borderTopLeftRadius: 10,borderTopRightRadius: 10}}								
										/>
										}
									</Grid>
									<Grid md={12} xs={12}>
										<Link href="/" className="loginfrgot" >Back to login</Link>
									</Grid>
									
									{!submitResponse?
									<Grid md={12} xs={12}>
										<Button id="loginbtnid" onClick={()=>{submit()}} className="loginbtn" variant="contained">SUBMIT</Button>
										<LoadingButton 
											className="loginbtn"
											loading
											loadingPosition="start"											
											variant="outlined"
											color="secondary"
											id="waitloginbtnid" 
											style={{textTransform: 'none',display:'none'}}
										>
											Wait...
										</LoadingButton>
									</Grid>
									:null}
								
								</Grid>
							</Grid>
						</Grid>
				</Box>




				</Box>
				
			</div>
		);
 
}