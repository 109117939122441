import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {Box, Grid, Button} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {MetaTags} from 'react-meta-tags';
import axios from 'axios';
import Cookies from 'js-cookie';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import DialogTeacherDetails from '../components/DialogTeacherDetails';
import DialogTeacherSave from '../components/DialogTeacherSave';
import { useSelector } from 'react-redux';
import {API_URL} from '../utils/EnvVars';
import Header from "./HeaderMy";
///import moment from 'moment';
import '../styles/bizofficer-a.css';
import 'font-awesome/css/font-awesome.min.css';

var vertical='top',horizontal='center';
  
const columns = [
	{ field: 'id', headerName: '#', width: 40 },
	{ field: 'loginId', headerName: 'Login Id', flex: 1, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), },
	{ field: 'teacherName', headerName: 'Name', flex: 1, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), },
	{ field: 'emailId', headerName: 'Email', flex: 1, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), },	
	{ field: 'accountStatus', headerName: 'Status', flex: 1, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), }
	/*
	{
	  field: 'age',
	  headerName: 'Age',
	  type: 'number',
	  width: 130,
	},
	{
	  field: 'fullName',
	  headerName: 'Full name',
	  description: 'This column has a value getter and is not sortable.',
	  sortable: false,
	  width: 160,
	  valueGetter: (params) =>
		`${params.getValue(params.id, 'firstName') || ''} ${
		  params.getValue(params.id, 'lastName') || ''
		}`,
	},
	*/
  ];
  
  export default function Execute() {
	const userObj = useSelector(state => state.userDetails);	
	const isloggedin = Cookies.get('isacademyloggedin');
	const childRef = React.useRef();
	const childRefNewSave = React.useRef(); 
	const [linearProgress, setLinearProgress] = React.useState(true);
	const [severityAlert, setSeverityAlert] = React.useState("error");
	const [showAlert, setShowAlert] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");

	const [filter, setFilter] = React.useState(false);
	const [keyword, setKeyword] = React.useState("");
	const [teacherObj, setTeacherObj] = React.useState({});
	const [rows, setRows] = React.useState([]);
	///const [organisationList, setOrganisationList] = React.useState([]);

	var myCurrentDate=new Date();
	var myPastFromDate=new Date(myCurrentDate);
    myPastFromDate.setDate(myPastFromDate.getDate() - 366);
	///const [fromDate, setFromDate] = React.useState(moment(myPastFromDate).format('YYYY-MM-DD'));
	///const [toDate, setToDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'));


	// check if logged in
	React.useEffect(() => {
		if(!isloggedin){
			window.location.href='login';
		}
	}, [isloggedin]);

	React.useEffect(() => {		
		if(userObj.emailId && userObj.token){
			getApiData(userObj.emailId,userObj.token,keyword);
		}
	}, [userObj,filter]);

	async function getApiData(emailId,token,keyword){
			let axiosHeader = {
				headers:{
					'Content-Type': 'multipart/mixed',
					'emailId': emailId,
					'token': token,
				}
			};
			axios.get(API_URL+'/teachers?keyword='+keyword, axiosHeader)
			   .then(response => {
				setLinearProgress(false);
			 	///console.log("Just Checking >> "+response.data);  
				 if(response.data.responseTxt==="success"){ 
					setRows(response.data.list);

			   	}else if(response.data.responseTxt==="unauthorized"){
				   Cookies.remove('isacademyloggedin');
				   window.location.href='login';

			   	}				 	

			}).catch((error) => {
				setSeverityAlert("error");
				setShowAlert(true);
				setAlertMessage(""+error);			
				return false;
			})
	}


	function newTeacher(){
		childRefNewSave.current.handleClickOpen();
	}

	function goToDetail(obj){
		if(obj && rows[obj.id-1]){
			setTeacherObj(rows[obj.id-1]);
			childRef.current.handleClickOpen();
		}
	}

	function search(){
		setFilter(!filter);
	}

	function onEnter(event){
		if(event.key === 'Enter'){
			search();
		}
	}

	function hideAlert(){
		setShowAlert(false);
	}
	
	return (
		<div> 
				<MetaTags>
            		<title>Teachers</title>
          		</MetaTags>
				
				<Header title='Teachers' />
				{linearProgress?<LinearProgress />:null}

				<Box className='mainBox'>	
					{/* <Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>
									<h4 style={{color:'#f50057'}}>Click on any row to edit details</h4>
								</Grid>
							</Grid>
					</Box> */}

					<Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>

									<FormControl style={{float:'left',width:150,marginRight:60,marginTop:8}}>
										<TextField
										id="keywordtxt"
										label="Search"
										defaultValue={keyword}
										sx={{ width: 200 }}
										InputLabelProps={{
										shrink: true,
										}}
										onChange={event => setKeyword(event.target.value)}
										onKeyPress={(event)=>{onEnter(event)}}
										size='small'
										/>
									</FormControl>

									<Button onClick={() => {search()}} style={{marginTop:-5}} size="small" variant="contained" color="secondary" >Search</Button>

									<span style={{marginTop:30}}>	
										<i onClick={()=>{newTeacher()}} title="Add New Teacher" className="fa fa-plus-circle" style={{fontSize:40, color:'#0158dc',marginLeft:15, cursor:'pointer'}} />
										{/* <i title="Download" className="fa fa-download" style={{fontSize:40, color:'#333',marginLeft:15, cursor:'pointer'}} />		 */}
									</span>	
								</Grid>
							</Grid>
					</Box>

					<div className="tblReport" style={{height: '78vh', width: '100%' }}>
						<DataGrid
							rows={rows}
							columns={columns}
							pageSize={50}
							rowHeight={40}
							rowsPerPageOptions={[50]}
							ActionsComponent={()=>{alert('Hello')}}		
							style={{fontSize:12}}	
							onRowClick={itm => goToDetail(itm)}			
							components={{
								NoRowsOverlay: () => (
								  <Stack height="100%" alignItems="center" justifyContent="center">
									<span style={{color:'#FF0000',fontWeight:'bold'}}>No teacher found!</span>
								  </Stack>
								)}}	
						/>
					</div>

				</Box>

			<DialogTeacherDetails teacherObj={teacherObj} ref={childRef} />								
			<DialogTeacherSave ref={childRefNewSave} />		

			<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity={severityAlert} sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
				</Stack>				
			
		</div>	
	);
  }