import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Grid, Box, Button} from '@material-ui/core';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from "@material-ui/core/styles";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {API_URL} from '../utils/EnvVars';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  paper: {
    fontSize: '12px !important',    
  }
});

var vertical='top',horizontal='center';

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CustomizedDialogs = React.forwardRef((props, ref) => {  
  const classes = useStyles();
  const userObj = useSelector(state => state.userDetails);
  const [open, setOpen] = React.useState(false); 
  const [severityAlert, setSeverityAlert] = React.useState("error");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [teacherLoginId, setTeacherLoginId] = React.useState("");
  const [teacherName, setTeacherName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [role, setRole] = React.useState("noselect");
  


  React.useImperativeHandle(ref, () => ({
    handleClickOpen(){
      setOpen(true);
    }
  }));
  
  const handleClose = () => {
    setOpen(false);
  };

  function hideAlert(){
		setShowAlert(false);
	}

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

	function saveData(){
    if(role==="noselect"){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Select teacher type.");			
      return false;
    }
    if(teacherLoginId===null || teacherLoginId.length<1){        
        setSeverityAlert("error");
				setShowAlert(true);
				setAlertMessage("Enter teacher login id");	
				return false;
    }    
    if(newPassword===null || newPassword.length<1){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Password can not be empty.");			
      return false;
    }
    if(teacherName===null || teacherName.length<1){        
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Enter teacher name");	
      return false;
    }
    if(email===null || email.length<1){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Email can not be empty.");	
      return false;
    }
    if(email!==null && email.length>0 && !validateEmail(email) ){
      setSeverityAlert("error");
      setShowAlert(true);
      setAlertMessage("Email is not valid.");			
      return false;
    }

		if(teacherName.length>1 && teacherLoginId.length>1 && newPassword.length>1){ 
			let axiosHeader = {
				headers: {
					'Content-Type': 'multipart/mixed',
          'token': userObj.token,
          'emailId': userObj.emailId,
				}
			}; 
			const data = new FormData();
      // data.append('schoolName', userObj.schoolName);
      // data.append('schoolId', userObj.schoolId);
      data.append('role', role);
      data.append('loginId', teacherLoginId);
      data.append('teacherName', teacherName);
      data.append('emailId', email);
      data.append('newPassword', newPassword);
             
			axios.post(API_URL+'/newteachercreation', data, axiosHeader)
			.then((response) => {
				console.log("RESPONSE RECEIVED: ", response.data.responseTxt);
				if(response.data.responseTxt==="success"){
          setTeacherLoginId("");
          setNewPassword("");
          setSeverityAlert("success");
          setOpen(false);
					setShowAlert(true);
					setAlertMessage("Teacher has been saved.");
          window.location.href.reload();	
					return false;
        }else if(response.data.responseTxt==="AlreadyExistsError"){
            setSeverityAlert("error");
            setOpen(true);
            setShowAlert(true);
            setAlertMessage("Teacher Login Id must be unique. It is associated with another teacher.");
            return false;  					

        }else if(response.data.responseTxt==="unauthorized"){
            Cookies.remove('isacademyloggedin');
            window.location.href='login';
 
				}else{
          setSeverityAlert("error");
					setShowAlert(true);
					setAlertMessage("Teacher details could not be saved.");			
					return false;
				}
			})
			.catch((error) => {
				console.log("Newteachercreation AXIOS ERROR: ", error);
			})	
		}		
	}
  
  return (
    <>
      <Dialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{marginBottom:'-30px'}} onClose={handleClose}></DialogTitle>
          <DialogContent style={{height:'88vh'}}>
            <Grid item   md={12} xs={12} className="test-instructions" >
                  <div className="btmbdrgry"><h3>New Teacher</h3></div>
                  <p>
                      <table style={{backgroundColor:'#000000',width:'100%' }} cellSpacing="1" cellPadding="3" >
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td style={{textAlign:'center'}} colSpan="2" >
                              <Select 
                                style={{width:'100%'}}
                                classes={{ paper: classes.paper }}
                                labelId="select-class-label"
                                id="select-class"
                                value={role}									
                                onChange={event => setRole(event.target.value)}
                                variant="standard"
                              >
                                  <MenuItem value="noselect">Select Type</MenuItem>
                                  <MenuItem value="TEACHER">TEACHER</MenuItem>
                                  <MenuItem value="PRINCIPAL">PRINCIPAL</MenuItem>
                              </Select>
                            </td>                            
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td>Teacher Login Id</td>
                            <td style={{textAlign:'center'}} >
                            <TextField onChange={event => setTeacherLoginId(event.target.value)} value={teacherLoginId} sx={{width:'99%',fontSize: 10}} autoComplete="none" variant="standard" />
                            </td>                            
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                          <td>Password</td>
                          <td style={{textAlign:'center'}} >
                          <TextField type='password' onChange={event => setNewPassword(event.target.value)} value={newPassword} autoComplete="off" style={{width:'99%'}} variant="standard" />
                          </td>
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                            <td>Teacher Name</td>
                            <td style={{textAlign:'center'}} >
                            <TextField onChange={event => setTeacherName(event.target.value)} value={teacherName} sx={{width:'99%',fontSize: 10}} variant="standard" />
                            </td>                            
                        </tr>
                        <tr style={{backgroundColor:'#FFFFFF'}} >
                          <td>Email</td>
                          <td style={{textAlign:'center'}} >
                          <TextField onChange={event => setEmail(event.target.value)} value={email} style={{width:'99%'}} variant="standard" />
                          </td>                            
                        </tr>

                      </table>

                      <Box mt={2}>
                          <Button onClick={() => {handleClose()}} style={{marginRight:12}} size="small" variant="outlined" >Cancel</Button>
					    	          <Button onClick={() => {saveData()}} style={{marginRight:12}} size="small" variant="contained" style={{backgroundColor:'#008000',color:'#FFF',marginRight:10}} >Save</Button>
								      </Box>

                  </p>
            </Grid>
          </DialogContent>
      </Dialog>
      
        <Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity={severityAlert} sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
				</Stack>

    </>
  );

});

export default CustomizedDialogs;