import { ADD_USER } from "./user.types";

export default function userDetails(state={}, action) {
	switch (action.type) {
		case ADD_USER:
			return action.user;
	  
		default:
		  return state;
   }
   
}
