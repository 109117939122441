import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {Box, Grid, Button} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {MetaTags} from 'react-meta-tags';
import axios from 'axios';
import Cookies from 'js-cookie';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import DialogStudentSave from '../components/DialogStudentSave';
import { useSelector } from 'react-redux';
import {API_URL} from '../utils/EnvVars';
import Header from "./HeaderMy";
import '../styles/bizofficer-a.css';
import 'font-awesome/css/font-awesome.min.css';

var vertical='top',horizontal='center';
  
const columns = [
	{ field: 'id', headerName: '#', width: 40 },	
	{ field: 'studentName', headerName: 'Name', width: 200, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), },
	{ field: 'sid', headerName: 'Account Id', width: 200, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), },   
	{ field: 'emailId', headerName: 'Email', width: 200, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), },
	{ field: 'className', headerName: 'Batch', width: 200, renderCell: (params) =>  (
		<Tooltip title={params.value} ><span>{params.value}</span></Tooltip>
	   ), }, 
	{ field: 'phoneNo', headerName: 'Phone', width: 200 }
  ];
  
  export default function Execute() {
	const userObj = useSelector(state => state.userDetails);	
	const isloggedin = Cookies.get('isacademyloggedin');
	const childRefNewSave = React.useRef();
	const [severityAlert, setSeverityAlert] = React.useState("error");
	const [showAlert, setShowAlert] = React.useState(false);
	const [alertMessage, setAlertMessage] = React.useState("");
  
	const [filter, setFilter] = React.useState(false);
	const [keyword, setKeyword] = React.useState("");
	const [studentObj, setStudentObj] = React.useState({});
	const [rows, setRows] = React.useState([]);
	const [classes, setClasses] = React.useState([]);

	// check if logged in
	React.useEffect(() => {
		if(!isloggedin){
			window.location.href='login';
		}
	}, [isloggedin]);

	React.useEffect(() => {		
		if(userObj.emailId && userObj.token){
			getApiData(userObj.emailId,userObj.token,keyword);
		}
	}, [userObj,filter]);

	async function getApiData(emailId,token,keyword){
			let axiosHeader = {
				headers:{
					'Content-Type': 'multipart/mixed',
					'emailId': emailId,
					'token': token,
				}
			};
			axios.get(API_URL+'/schoolstudents?keyword='+keyword, axiosHeader)
			   .then(response => {
			 	///console.log("Just Checking >> "+response.data);  
				if(response.data.responseTxt==="success"){ 
				 	setRows(response.data.students);	
					setClasses(response.data.classes);					
				}else if(response.data.responseTxt==="unauthorized"){
					Cookies.remove('isacademyloggedin');
    				window.location.href='login';

				}else{
					setSeverityAlert("error");
				    setShowAlert(true);
					setAlertMessage("Something wrong happened");			
					return false;
				} 			 				
			}).catch((error) => {
				setSeverityAlert("error");
				setShowAlert(true);
				setAlertMessage(""+error);			
				return false;
			})
	}


	function newStudent(){	
		setStudentObj(null);	
		childRefNewSave.current.handleClickOpen();
	}

	function editDetail(obj){
		if(obj && rows[obj.id-1]){
			setStudentObj(rows[obj.id-1]);
			childRefNewSave.current.handleClickOpen();
		}
	}

	function search(){
		setFilter(!filter);
	}

	function onEnter(event){
		if(event.key === 'Enter'){
			search();
		}
	}

	function hideAlert(){
		setShowAlert(false);
	}
	
	return (
		<div> 
				<MetaTags>
            		<title>Students</title>
          		</MetaTags>
				
				<Header title="Students" />
				{(document.readyState!=="complete")?<LinearProgress />:null}

				<Box className='mainBox'>	
{/* 
					<Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>
									<h3 className="heading" >Students</h3>
									<h4 style={{color:'#f50057'}}>Click on any row to edit details</h4>
								</Grid>
							</Grid>
					</Box> */}

					<Box mt={1}>
							<Grid container spacing={2}>
								<Grid item md={12} xs={12}>

									<FormControl style={{width:150,marginRight:60,marginTop:8}}>
										<TextField
										id="keywordtxt"
										label="Search"
										defaultValue={keyword}
										sx={{ width: 200 }}
										InputLabelProps={{
										shrink: true,
										}}
										size='small'
										onChange={event => setKeyword(event.target.value)}
										onKeyPress={(event)=>{onEnter(event)}}
										/>
									</FormControl>

									<Button onClick={() => {search()}} style={{marginTop:-4}} size="small" variant="contained" color="secondary" >Search</Button>

									<span style={{marginTop:30}}>	
										<i onClick={()=>{newStudent()}} title="Create New Student" className="fa fa-plus-circle" style={{fontSize:40, color:'#0158dc',marginLeft:15, cursor:'pointer'}} />
										{/* <i title="Download" className="fa fa-download" style={{fontSize:40, color:'#333',marginLeft:15, cursor:'pointer'}} />		 */}
									</span>	
								</Grid>
							</Grid>
					</Box>

					<div className="tblReport" style={{height: '78vh', width: '100%' }}>
						<DataGrid
							rows={rows}
							columns={columns}
							pageSize={50}
							rowHeight={40}
							rowsPerPageOptions={[50]}
							ActionsComponent={()=>{alert('Hello')}}		
							style={{fontSize:12}}	
							onRowClick={itm => editDetail(itm)}			
							components={{
								NoRowsOverlay: () => (
								  <Stack height="100%" alignItems="center" justifyContent="center">
									<span style={{color:'#FF0000',fontWeight:'bold'}}>No student found!</span>
								  </Stack>
								)}}	
						/>
					</div>

				</Box>

			<DialogStudentSave studentObj={studentObj} classes={classes} ref={childRefNewSave} />						
			
			<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar open={showAlert} autoHideDuration={6000} onClose={hideAlert} anchorOrigin={{vertical,horizontal}}  >
						<Alert onClose={hideAlert} variant="filled" severity={severityAlert} sx={{ width: '100%' }}>
							{alertMessage}
						</Alert>
					</Snackbar>
				</Stack>

		</div>	
	);
  }